import { flow, observable, makeObservable } from "mobx";
import FileDownload from "js-file-download";
import * as apiBecv from "../services/apiBecv";

class Template {
    listTemplate = [];
    templateSelected = {};
    html = "";
    htmlId = "";

    getTemplates = flow(function* () {
		try {
			const {data} = yield apiBecv.getTemplates();
			this.listTemplate = data;
		} catch (e) {

		}
	});

    getCVTemplate = flow(function* () {
		try {
			let cvId = window.__RUNTIME_CONFIG__.id;
			const {data} = yield apiBecv.getCVTemplate(this.templateSelected.id, cvId);
			this.html = data.html;
			this.htmlId = data.htmlId;
		} catch (e) {

		}
	});

    exportPdf = flow(function* () {
		try {
			let cvId = window.__RUNTIME_CONFIG__.id;
			const {data} = yield apiBecv.exportPdf(this.htmlId);
			FileDownload(data, cvId.concat(".pdf"));
		} catch (e) {

		}
	});

    constructor() {
        makeObservable(this, {
            listTemplate: observable,
            templateSelected: observable,
            html: observable,
            htmlId: observable
        });
    }
}

const templateStore = new Template();

export default templateStore;
