import { observable, makeObservable } from "mobx";
import {v4 as uuidv4} from 'uuid';

export default class Education {
	id = uuidv4();
	name = "";
	level = "";
	organization = "";
	gpa = "";
	timeStart = "";
	timeEnd = "";
	moreInfo = "";

	constructor(data) {
        makeObservable(this, {
            name: observable,
            level: observable,
            organization: observable,
            gpa: observable,
            timeStart: observable,
            timeEnd: observable,
            moreInfo: observable
        });

        if (data) {
			Object.keys(data).map(key => this[key] = data[key]);
		}
    }
}
