import { observable, makeObservable } from "mobx";
import {v4 as uuidv4} from 'uuid';

export default class Language {
	id = uuidv4();
	nameLanguage = "";
	overview = "";
	listen = "";
	read = "";
	speak = "";
	write = "";

	constructor(data) {
        makeObservable(this, {
            nameLanguage: observable,
            overview: observable,
            listen: observable,
            read: observable,
            speak: observable,
            write: observable
        });

        if (data) {
			Object.keys(data).map(key => this[key] = data[key]);
		}
    }
}
