import {flow, makeAutoObservable} from "mobx";
import Education from "./Education";
import _ from "lodash";
import Certificate from "./Certificate";
import Prize from "./Prize";
import PersonalCapacity from "./PersonalCapacity";
import Experience from "./Experience";
import Language from "./Language";
import SocialActivity from "./SocialActivity";
import ComparePersonal from "./ComparePersonal";
import * as apiBecv from "../services/apiBecv";
import * as storage from "../services/storage";
import {BECV_ID} from "../constants";
import {toast} from 'react-toastify';

export class Cv {	loadVersion = 0;
	id = "";
	personId = "";
	variantId = "";
	variantAlias = "";
	checkCreateNewVersion = "";
	checkCreateVariant = "";
	version = "";
	temporary = false;
	targetCompany = "";
	currentCompanyName = "";
	firstName = "";
	lastName = "";
	englishName = "";
	address = "";
	role = "";
	email = "";
	phone = "";
	birthday = "";
	timeCreate = "";
	timeUpdate = "";
	gender = true;
	urlImage = "";
	description = "";
	educations = [];
	certificates = [];
	prizes = [];
	personalCapacities = [];
	experiences = [];
	foreignLanguages = [];
	socialActivities = [];
	comparePersonals = [];
	state = "done"; // "pending" / "saved" / "done" / "error"
	file = null;

	constructor() {
		makeAutoObservable(this);
    }

	setField(name, value) {
		this[name] = value;
	}

	getField(name) {
		return this[name];
	}

	getFields() {
		const {
			loadVersion,
			id,
			personId,
			variantId,
			variantAlias,
			checkCreateNewVersion,
			checkCreateVariant,
			version,
			temporary,
			targetCompany,
			currentCompanyName,
			firstName,
			lastName,
			englishName,
			address,
			role,
			email,
			phone,
			birthday,
			timeCreate,
			timeUpdate,
			gender,
			urlImage,
			description,
			educations,
			certificates,
			prizes,
			personalCapacities,
			experiences,
			foreignLanguages,
			socialActivities,
			comparePersonals
		} = this;
		return {
			loadVersion,
			id,
			personId,
			variantId,
			variantAlias,
			checkCreateNewVersion,
			checkCreateVariant,
			version,
			temporary,
			targetCompany,
			currentCompanyName,
			firstName,
			lastName,
			englishName,
			address,
			role,
			email,
			phone,
			birthday,
			timeCreate,
			timeUpdate,
			gender,
			urlImage,
			description,
			educations,
			certificates,
			prizes,
			personalCapacities,
			experiences,
			foreignLanguages,
			socialActivities,
			comparePersonals
		};
	}

	callTimes = 0;

	getCv = flow(function* () {
		try {
			this.state = "pending";
			const {data} = yield apiBecv.getCv(this.id);
			console.log("Call time: " + ++this.callTimes);
			this.loadData(data);
			this.state = "done";
			return data;
		} catch (e) {
			this.state = "error";
			console.log(e);
			return {};
		}
	});

	loadData(data) {
		this.setField('loadVersion', this.getField('loadVersion') + 1);
		Object.keys(data).forEach(key => {
			if (Array.isArray(data[key])) {
				let newData = [];
				data[key].map(function (value) {
					if (key === "educations") {
						newData.push(new Education(value));
					}
					if (key === "certificates") {
						newData.push(new Certificate(value));
					}
					if (key === "prizes") {
						newData.push(new Prize(value));
					}
					if (key === "personalCapacities") {
						newData.push(new PersonalCapacity(value));
					}
					if (key === "experiences") {
						newData.push(new Experience(value));
						window.global_exps = newData;
					}
					if (key === "foreignLanguages") {
						newData.push(new Language(value));
					}
					if (key === "socialActivities") {
						newData.push(new SocialActivity(value));
					}
					if (key === "comparePersonals") {
						newData.push(new ComparePersonal(value));
					}
					return this;
				});
				this.setField(key, newData);
				// console.log("Key: " + key + " >> Data (Array): " + newData);
				// console.log("Set value: " + this.getField(key));
			} else {
				this.setField(key, data[key]);
				// console.log("Key: " + key + " >> Data (Raw): " + data[key]);
				// console.log("Set value: " + this.getField(key));
			}
		});
	};

	saveCv = flow(function* (callback) {
		try {
			this.state = "pending";
			if (this.file) {
				const formData = new FormData();
				formData.append("file", this.file);
				const { data } = yield apiBecv.updateAvatar(formData);
				this.urlImage = data;
			}
			yield apiBecv.saveCv(this.getFields());
			storage.save(BECV_ID, this.id);
			if (this.temporary) {
				this.state = "saved";
				toast.success("🦄 Your CV is saved");
			} else {
				this.state = "done";
				toast.success("🦄 Your CV is ready");
			}
		} catch (e) {
			if (e.response && e.response.status === 409) {
				toast.error(e.response.request.responseText || "Ops!");
			}
			this.state = "error";
		} finally {
			callback(this.state);
		}
	});

	addEducation() {
		this.educations.push(new Education());
	}

	removeEducation(id) {
		_.remove(this.educations, value => value.id === id);
	}

	addCertificate() {
		this.certificates.push(new Certificate());
	}

	removeCertificate(id) {
		_.remove(this.certificates, value => value.id === id);
	}

	addPrize() {
		this.prizes.push(new Prize());
	}

	removePrize(id) {
		_.remove(this.prizes, value => value.id === id);
	}

	addPersonalCapacity() {
		this.personalCapacities.push(new PersonalCapacity());
	}

	removePersonalCapacity(id) {
		_.remove(this.personalCapacities, value => value.id === id);
	}

	addExperience() {
		this.experiences.push(new Experience());
	}

	removeExperience(id) {
		_.remove(this.experiences, value => value.id === id);
	}

	addForeignLanguage() {
		this.foreignLanguages.push(new Language());
	}

	removeForeignLanguage(id) {
		_.remove(this.foreignLanguages, value => value.id === id);
	}

	addSocialActivity() {
		this.socialActivities.push(new SocialActivity());
	}

	removeSocialActivity(id) {
		_.remove(this.socialActivities, value => value.id === id);
	}

	addComparePersonal() {
		this.comparePersonals.push(new ComparePersonal());
	}

	removeComparePersonal(id) {
		_.remove(this.comparePersonals, value => value.id === id);
	}


}

const cvStore = new Cv();
const cvStoreTemplate = new Cv();
if (!window.__RUNTIME_CONFIG__) {
	window.__RUNTIME_CONFIG__ = {};
}
window.__RUNTIME_CONFIG__.defaultTemplate = cvStoreTemplate;

export default cvStore;

