import { observable, makeObservable } from "mobx";
import {v4 as uuidv4} from 'uuid';

export default class SocialActivity {
	id = uuidv4();
	nameActivity = "";
	timeStart = "";
	timeEnd = "";
	moreInfo = "";

	constructor(data) {
        makeObservable(this, {
            nameActivity: observable,
            timeStart: observable,
            timeEnd: observable,
            moreInfo: observable
        });

        if (data) {
			Object.keys(data).map(key => this[key] = data[key]);
		}
    }
}
