import axios from "axios";
import {stringify} from 'flatted';

const arr = [{}];
arr[0].arr = arr;
arr.push(arr);

const result = stringify(arr); // [["1","0"],{"a":"0"}]

console.log(result); // 👉️ [["1","0"],{"arr":"0"}]

let env = process.env.NODE_ENV;
if (process.env.NODE_ENV_DEV_OVERRIDE) {
  env = process.env.NODE_ENV_DEV_OVERRIDE;
}

const baseUrl = window.__RUNTIME_CONFIG__.API_URL;
if (!window.__RUNTIME_CONFIG__) {
  window.__RUNTIME_CONFIG__ = {};
}
window.__RUNTIME_CONFIG__.baseUrl = baseUrl;
window.__RUNTIME_CONFIG__.varEnv = env;

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export const getCv = (id) => {
  return axios.get(baseUrl.concat("cv").concat(`/${id}`));
};

// get cv theo nhiều 2 trường
export const getOneCv = (id, personId) => {
  return axios.get(baseUrl.concat("cv").concat(`/${id}/${personId}`));
};

//test get theo personId
export const getCvPerson = (personId) => {
  return axios.get(baseUrl.concat("cv/person").concat(`/${personId}`));
};

export const updateAvatar = (formData) => {
  return axios.post(baseUrl.concat("upload/file"), formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

// const getCircularReplacer = () => {
//   const seen = new WeakSet();
//   return (key, value) => {
//     if (typeof value === 'object' && value !== null) {
//       if (seen.has(value)) {
//         return;
//       }
//       seen.add(value);
//     }
//     return value;
//   };
// };

export const saveCv = (cv) => {
  return axios.post(baseUrl.concat("cv"), cv);
};
export const updateCv = (cv) => {
  return axios.put(baseUrl.concat("cv"), cv);
};

export const getTemplates = () => {
  return axios.get(baseUrl.concat("templates"));
};

export const getCVTemplate = (templateId, cvId) => {
  return axios.post(
    baseUrl.concat("templates/").concat(templateId).concat(`?cvId=${cvId}`)
  );
};

export const exportPdf = (cvTemplateId) => {
  fetch(baseUrl.concat("templates/export-pdf/").concat(cvTemplateId))
    .then(response => {
      let fileName = decodeURIComponent(response.headers.get("File-Name"));
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();
      });
      //window.location.href = response.url;
    });
};
