import React, {lazy, Suspense} from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import NotFound from "./pages/NotFound";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

import { createBrowserHistory as createHistory } from "history";

const EnterInformationPage = lazy(() => import('./pages/EnterInformation'));
const TemplatePage = lazy(() => import('./pages/Templates'));

class App extends React.Component {

    history = createHistory(this.props);

    state = {
        buildTime: '',
        serverAPI: '',
        varEnv: '',
        serverManagement: '',
        serverPerson: ''
    }

    componentDidMount() {
        let _this = this;
        this.serverRequest =
            axios
                .get(process.env.PUBLIC_URL + "buildInfo.json")
                .then(function (result) {
                    window.__RUNTIME_CONFIG__.buildTime = result.data.buildTime;
                    _this.setState({
                        buildTime: "Build Date: " + window.__RUNTIME_CONFIG__.buildTime,
                        serverAPI: "API: " + window.__RUNTIME_CONFIG__.baseUrl,
                        varEnv: "Env: " + window.__RUNTIME_CONFIG__.varEnv,
                        serverManagement: window.__RUNTIME_CONFIG__.baseUrl.replace(/api\//g,''),
                        serverPerson: window.__RUNTIME_CONFIG__.baseUrl.replace(/api\//g,'person')
                    });
                    // _this.buildTime = React.createRef();
                    // _this.serverAPI = React.createRef();
                    // _this.varEnv = React.createRef();
                    // _this.serverManagement = React.createRef();
                    // _this.serverPerson = React.createRef();
                    // _this.buildTime.current.textContent = "Build Date: " + window.__RUNTIME_CONFIG__.buildTime;
                    // _this.serverAPI.current.textContent = "Env: " + window.__RUNTIME_CONFIG__.varEnv;
                    // _this.varEnv.current.textContent = "API: " + window.__RUNTIME_CONFIG__.baseUrl;
                    // _this.serverManagement.current.textContent =  window.__RUNTIME_CONFIG__.baseUrl.replace(/api\//g,'');
                    // _this.serverPerson.current.textContent =  window.__RUNTIME_CONFIG__.baseUrl.replace(/api\//g,'person');
                })
    }

    componentWillUnmount() {
    }

    render() {
        return (<BrowserRouter history={this.history}>
                <Suspense fallback={<div>Loading...</div>}>

                    <div className="floating-on-top">
                        <div>{this.state.buildTime}</div>
                        <div>{this.state.serverAPI}</div>
                        <div>{this.state.varEnv}</div>
                        <a href={this.state.serverManagement}>Go to CV List</a><br/>
                        <a href={this.state.serverPerson}>Go to Person list</a>
                    </div>
                    <Routes>
                        <Route exact path="/" element={<EnterInformationPage/>} />
                        <Route path="/templates" element={<TemplatePage/>}/>
                        <Route element={<NotFound/>}/>
                    </Routes>
                </Suspense>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnVisibilityChange
                    draggable
                    pauseOnHover
                />
                {/* Same as */}
                <ToastContainer/>
            </BrowserRouter>
        )
    }
}

export default App;
