export function save(key, value) {
	localStorage.setItem("@".concat(key), value);
}
export function update(key, value) {
	localStorage.setItem("@".concat(key), value);
}

export function get(key) {
	return localStorage.getItem("@".concat(key));
}
