import { observable, makeObservable } from "mobx";
import {v4 as uuidv4} from 'uuid';
import _ from "lodash";

class Time {
	id = uuidv4();
	timeStart = "";
	timeEnd = "";
	projectName = "";

	constructor(data) {
        makeObservable(this, {
            timeStart: observable,
            timeEnd: observable,
            projectName: observable
        });

        if (data) {
			Object.keys(data).map(key => this[key] = data[key]);
		}
    }
}

export default class Tech {
	id = uuidv4();
	toolAndTechnology = "";
	exp = "";
	level = "";
	status = "";
	times = [];

	constructor(data) {
        makeObservable(this, {
            toolAndTechnology: observable,
            exp: observable,
            level: observable,
            status: observable,
            times: observable
        });

        if (data) {
			Object.keys(data).map(key => {
				if (Array.isArray(data[key])) {
					let newData = [];
					data[key].map(value => newData.push(new Time(value)));
					this.times = newData;
				} else {
				this[key] = data[key];
				}
				return this;
			});
		} else {
			this.addTime();
		}
    }

	addTime() {
		this.times.push(new Time());
	}

	removeTime(id) {
		_.remove(this.times, value => value.id === id);
	}
}
