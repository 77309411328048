import React from "react";
import {inject, observer} from "mobx-react";

class NotFound extends React.Component {
	componentDidMount() {

	}

	render() {
		return null;
	}
}

export default inject("store")(observer(NotFound));