import { observable, makeObservable } from "mobx";
import {v4 as uuidv4} from 'uuid';

export default class ComparePersonal {
	id = uuidv4();
	name = "";
	position = "";
	contact = "";

	constructor(data) {
        makeObservable(this, {
            name: observable,
            position: observable,
            contact: observable
        });

        if (data) {
			Object.keys(data).map(key => this[key] = data[key]);
		}
    }
}
