import { computed, observable, makeObservable } from "mobx";
import {v4 as uuidv4} from 'uuid';
import _ from "lodash";
import Tech from "./Tech";

export default class PersonalCapacity {
	id = uuidv4();
	name = "";
	techStackList = [];

	constructor(data) {
        makeObservable(this, {
            name: observable,
            techStackList: observable,
            report: computed
        });

        if (data) {
			Object.keys(data).map(key => {
				if (Array.isArray(data[key])) {
					let newData = [];
					data[key].map(value => newData.push(new Tech(value)));
					this.techStackList = newData;
				} else {
					this[key] = data[key];
				}
				return this;
			});
		} else {
			this.addTechStack();
		}
    }

	get report() {
		return this.name;
	}

	addTechStack() {
		this.techStackList.push(new Tech());
	}

	removeTechStack(id) {
		_.remove(this.techStackList, value => value.id === id);
	}

}
