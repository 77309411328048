import { observable, makeObservable } from "mobx";
import {v4 as uuidv4} from 'uuid';
import _ from "lodash";

class Project {
	id = uuidv4();
	nameProject = "";
	description = "";
	customer = "";
	totalMember = 0;
	position = "";
	responsibility = "";
	techStacks = "";
	techUse = "";
	timeStart = "";
	timeEnd = "";

	constructor(data, experiences) {
        makeObservable(this, {
            nameProject: observable,
            description: observable,
            customer: observable,
            totalMember: observable,
            position: observable,
            responsibility: observable,
            techStacks: observable,
            techUse: observable,
            timeStart: observable,
            timeEnd: observable
        });

        if (data) {
			Object.keys(data).map(key => this[key] = data[key]);
		}
    }
}

export default class Experience {
	id = uuidv4();
	nameCompany = "";
	timeStart = "";
	timeEnd = "";
	position = "";
	jobDescription = "";
	projectList = [];


	constructor(data, experiences) {
        makeObservable(this, {
            nameCompany: observable,
            timeStart: observable,
            timeEnd: observable,
            position: observable,
            jobDescription: observable,
            projectList: observable
        });

        if (data) {
			Object.keys(data).map(key => {
				if (Array.isArray(data[key])) {
					let newData = [];
					data[key].map(value => newData.push(new Project(value, this.experiences)));
					this.projectList = newData;
				} else {
					this[key] = data[key];
				}
				return this;
			});
		} else {
			this.addProject();
		}
    }

	addProject() {
		this.projectList.push(new Project());
	}

	addExistedProject(project) {
		this.projectList.push(project);
	}

	removeProject(id) {
		_.remove(this.projectList, value => value.id === id);
	}

}
